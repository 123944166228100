var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('p', {
    staticClass: "font-w600 mb-2"
  }, [_vm._v("Selected Options (" + _vm._s(_vm.value.length) + " items)")]), _c('div', {
    staticClass: "MultiSelectOptions mb-3"
  }, [!_vm.value || _vm.value.length === 0 ? _c('div', {
    staticClass: "alert alert-warning mb-2"
  }, [_vm._v("No options selected")]) : _c('div', {
    staticClass: "bg-lightest rounded-md py-2 px-3"
  }, [_c('div', {
    staticClass: "MultiSelectOptions-dropdown"
  }, _vm._l(_vm.value, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "d-flex justify-content-between align-items-center my-2 MultiSelectOptions-item"
    }, [_c('div', {
      staticClass: "font-w600"
    }, [_vm._v(" " + _vm._s(_vm.options.find(function (option) {
      return option.value === item;
    }) ? _vm.options.find(function (option) {
      return option.value === item;
    }).label : item) + " ")]), _c('button', {
      staticClass: "btn btn-sm text-danger",
      attrs: {
        "type": "button"
      },
      on: {
        "click": function ($event) {
          return _vm.removeFromList(item, index);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-trash"
    })])]);
  }), 0)]), _c('div', {
    staticClass: "text-right mt-1"
  }, [_vm.value.length > 0 ? _c('a', {
    staticClass: "text-primary font-w600",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onClickClear.apply(null, arguments);
      }
    }
  }, [_vm._v(" Unselect All")]) : _vm._e()])]), _c('p', {
    staticClass: "font-w600 mb-2"
  }, [_vm._v("Available Options (" + _vm._s(_vm.visibleOptions.length) + " items)")]), _c('div', {
    staticClass: "MultiSelectOptions"
  }, [_vm.search ? _c('div', {
    staticClass: "form-group MultiSelectOptions-form mb-2"
  }, [_c('div', {
    staticClass: "input-group"
  }, [_c('input', {
    staticClass: "form-control",
    attrs: {
      "placeholder": "Search..."
    },
    on: {
      "input": _vm.onSearch
    }
  }), _vm._m(0)])]) : _vm._e(), !_vm.visibleOptions || _vm.visibleOptions.length === 0 ? _c('div', {
    staticClass: "alert alert-warning mb-1"
  }, [_vm._v("No options available")]) : _c('div', {
    staticClass: "MultiSelectOptions-dropdown-wrapper"
  }, [_c('ul', {
    staticClass: "MultiSelectOptions-dropdown"
  }, _vm._l(_vm.visibleOptions, function (item, index) {
    return _c('li', {
      key: index,
      staticClass: "MultiSelectOptions-dropdown-item d-flex justify-content-between align-items-center font-w600",
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.addToList(item, index);
        }
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0), _c('div', {
    staticClass: "mt-2 text-right"
  }, [_vm.visibleOptions.length !== 0 ? _c('a', {
    staticClass: "text-primary font-w600 ml-2 mr-2",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onClickSelectAll.apply(null, arguments);
      }
    }
  }, [_vm._v("Select All")]) : _vm._e(), _vm.visibleOptions.length === 0 ? _c('a', {
    staticClass: "text-primary font-w600 ml-2 mr-2",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onClickClear.apply(null, arguments);
      }
    }
  }, [_vm._v("Unselect All")]) : _vm._e(), _c('a', {
    staticClass: "text-primary font-w600 ml-2",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onClickClear.apply(null, arguments);
      }
    }
  }, [_vm._v("Clear")])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-group-append"
  }, [_c('span', {
    staticClass: "input-group-text"
  }, [_c('i', {
    staticClass: "fa fa-fw fa-search"
  })])]);

}]

export { render, staticRenderFns }