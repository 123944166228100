var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('select', _vm._b({
    ref: "input",
    staticClass: "js-select2",
    class: {
      'select2-transparent': _vm.transparent
    },
    attrs: {
      "data-placeholder": _vm.placeholder
    },
    domProps: {
      "value": _vm.value
    }
  }, 'select', _vm.$attrs, false), [_vm.allowClear ? _c('option') : _vm._e(), _vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }