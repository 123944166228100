var render = function render(){
  var _vm$config, _vm$config2, _vm$config3, _vm$config4, _vm$config5, _vm$config6, _vm$config7;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.type === 'datePicker' ? _c('DatePicker', {
    staticClass: "form-control",
    class: {
      'is-invalid': !!_vm.errorMessage,
      'form-control-alt': _vm.isAlt || _vm.alt,
      disabled: _vm.disabled
    },
    attrs: {
      "id": _vm.id,
      "name": _vm.id,
      "type": "text",
      "placeholder": _vm.filledPlaceholder,
      "value": _vm.value,
      "format": _vm.dateFormat || 'dd/mm/yyyy',
      "min-view-mode": _vm.dateMinViewMode
    },
    on: {
      "input": _vm.onInput,
      "error": _vm.onInputError
    }
  }) : _vm.type === 'dateTimePicker' ? _c('DateTimePicker', {
    class: {
      'is-invalid': !!_vm.errorMessage,
      'form-control-alt': _vm.isAlt || _vm.alt
    },
    attrs: {
      "id": _vm.id,
      "name": _vm.id,
      "placeholder": _vm.filledPlaceholder,
      "disabled": _vm.disabled,
      "value": _vm.value,
      "config": _vm.config,
      "format": _vm.dateFormat || 'DD/MM/YYYY HH:mm',
      "min-view-mode": _vm.dateMinViewMode
    },
    on: {
      "input": _vm.onInput
    }
  }) : _vm.type === 'dateRangePicker' ? _c('DateRangePicker', {
    class: {
      'is-invalid': !!_vm.errorMessage,
      'form-control-alt': _vm.isAlt || _vm.alt
    },
    attrs: {
      "id": _vm.id,
      "name": _vm.id,
      "placeholder": _vm.filledPlaceholder,
      "disabled": _vm.disabled,
      "value": _vm.value,
      "min-view-mode": _vm.dateMinViewMode,
      "custom-date-range": _vm.dateRange,
      "config": _vm.config,
      "format": _vm.dateFormat || ((_vm$config = _vm.config) !== null && _vm$config !== void 0 && _vm$config.time ? 'DD MMM YYYY HH:mm' : 'DD/MM/YYYY')
    },
    on: {
      "input": _vm.onInput
    }
  }) : _vm.type === 'select2' || _vm.type === 'select' ? _c('Select2', {
    staticClass: "form-control",
    class: {
      'form-control-alt': _vm.isAlt || _vm.alt
    },
    attrs: {
      "id": _vm.id,
      "name": _vm.id,
      "type": "text",
      "disabled": _vm.disabled,
      "placeholder": _vm.filledPlaceholder,
      "value": _vm.value,
      "allow-search": _vm.type === 'select2' || ((_vm$config2 = _vm.config) === null || _vm$config2 === void 0 ? void 0 : _vm$config2.allowSearch),
      "allow-clear": (_vm$config3 = _vm.config) === null || _vm$config3 === void 0 ? void 0 : _vm$config3.allowClear,
      "transparent": (_vm$config4 = _vm.config) === null || _vm$config4 === void 0 ? void 0 : _vm$config4.transparent,
      "error": _vm.errorMessage
    },
    on: {
      "input": _vm.onInput
    }
  }, [_vm.options.some(function (o) {
    return o.children;
  }) ? _vm._l(_vm.options, function (option) {
    return _c('optgroup', {
      key: option.title,
      attrs: {
        "label": option.title
      }
    }, _vm._l(option.children, function (child) {
      return _c('option', {
        key: child.value,
        attrs: {
          "icon": child.icon,
          "level": child.level
        },
        domProps: {
          "value": child.value
        }
      }, [_vm._v(" " + _vm._s(child.label) + " ")]);
    }), 0);
  }) : _vm._l(_vm.options, function (option) {
    return _c('option', {
      key: option.value,
      attrs: {
        "icon": option.icon,
        "level": option.level
      },
      domProps: {
        "value": option.value
      }
    }, [_vm._v(" " + _vm._s(option.label) + " ")]);
  })], 2) : _vm.type === 'select-array' ? _c('MultiSelectText', {
    class: {
      'is-invalid': !!_vm.errorMessage,
      'form-control-alt': _vm.isAlt || _vm.alt,
      disabled: _vm.disabled
    },
    attrs: {
      "id": _vm.id,
      "name": _vm.id,
      "disabled": _vm.disabled,
      "placeholder": _vm.filledPlaceholder,
      "value": _vm.value,
      "unique": _vm.uniqueOnly
    },
    on: {
      "input": _vm.onInput,
      "error": _vm.onInputError
    }
  }) : _vm.type === 'select-array-list' ? _c('MultiSelectOptions', {
    class: {
      'is-invalid': !!_vm.errorMessage,
      'form-control-alt': _vm.isAlt || _vm.alt,
      disabled: _vm.disabled
    },
    attrs: {
      "id": _vm.id,
      "name": _vm.id,
      "disabled": _vm.disabled,
      "placeholder": _vm.filledPlaceholder,
      "value": _vm.value,
      "options": _vm.options,
      "overflow": _vm.overflow,
      "search": _vm.search
    },
    on: {
      "input": _vm.onInput,
      "itemAdded": _vm.onItemAdded,
      "itemRemoved": _vm.onItemRemoved
    }
  }) : _vm.type === 'select-array-search' ? _c('MultiSearchSelectOptions', {
    class: {
      'is-invalid': !!_vm.errorMessage,
      'form-control-alt': _vm.isAlt || _vm.alt,
      disabled: _vm.disabled
    },
    attrs: {
      "id": _vm.id,
      "label": _vm.label,
      "name": _vm.id,
      "disabled": _vm.disabled,
      "placeholder": _vm.filledPlaceholder,
      "value": _vm.value,
      "options": _vm.options,
      "overflow": _vm.overflow,
      "search": _vm.search,
      "action": _vm.action
    },
    on: {
      "input": _vm.onInput,
      "itemAdded": _vm.onItemAdded,
      "itemRemoved": _vm.onItemRemoved
    }
  }) : _vm.type === 'multi-check-options' ? _c('MultiCheckOptions', {
    class: {
      'is-invalid': !!_vm.errorMessage,
      'form-control-alt': _vm.isAlt || _vm.alt,
      disabled: _vm.disabled
    },
    attrs: {
      "id": _vm.id,
      "name": _vm.id,
      "disabled": _vm.disabled,
      "placeholder": _vm.filledPlaceholder,
      "value": _vm.value,
      "options": _vm.options,
      "overflow": _vm.overflow,
      "search": _vm.search
    },
    on: {
      "input": _vm.onInput,
      "itemAdded": _vm.onItemAdded,
      "itemRemoved": _vm.onItemRemoved
    }
  }) : _vm.type === 'tag-manager' ? _c('TagManager', {
    class: {
      'is-invalid': !!_vm.errorMessage,
      'form-control-alt': _vm.isAlt || _vm.alt,
      disabled: _vm.disabled
    },
    attrs: {
      "id": _vm.id,
      "name": _vm.id,
      "disabled": _vm.disabled,
      "type": "text",
      "placeholder": _vm.filledPlaceholder,
      "value": _vm.value
    },
    on: {
      "input": _vm.onInput,
      "itemAdded": _vm.onItemAdded,
      "itemRemoved": _vm.onItemRemoved
    }
  }) : _vm.type === 'text' ? _c('input', {
    staticClass: "form-control",
    class: {
      'is-invalid': !!_vm.errorMessage,
      'form-control-alt': _vm.isAlt || _vm.alt,
      disabled: _vm.disabled
    },
    attrs: {
      "id": _vm.id,
      "name": _vm.id,
      "disabled": _vm.disabled,
      "type": "text",
      "placeholder": _vm.filledPlaceholder
    },
    domProps: {
      "value": _vm.value
    },
    on: {
      "input": function (e) {
        return _vm.onInput(e.target.value);
      }
    }
  }) : _vm.type === 'number' ? _c('input', {
    staticClass: "form-control",
    class: {
      'is-invalid': !!_vm.errorMessage,
      'form-control-alt': _vm.isAlt || _vm.alt
    },
    attrs: {
      "id": _vm.id,
      "name": _vm.id,
      "type": "number",
      "placeholder": _vm.filledPlaceholder,
      "min": ((_vm$config5 = _vm.config) === null || _vm$config5 === void 0 ? void 0 : _vm$config5.min) || _vm.min,
      "max": ((_vm$config6 = _vm.config) === null || _vm$config6 === void 0 ? void 0 : _vm$config6.max) || _vm.max,
      "step": (_vm$config7 = _vm.config) === null || _vm$config7 === void 0 ? void 0 : _vm$config7.step
    },
    domProps: {
      "value": _vm.value
    },
    on: {
      "input": function (e) {
        return _vm.onInput(e.target.value);
      }
    }
  }) : _vm.type === 'password' ? _c('input', {
    staticClass: "form-control",
    class: {
      'is-invalid': !!_vm.errorMessage,
      'form-control-alt': _vm.isAlt || _vm.alt,
      disabled: _vm.disabled
    },
    attrs: {
      "id": _vm.id,
      "name": _vm.id,
      "disabled": _vm.disabled,
      "type": "password",
      "placeholder": _vm.filledPlaceholder
    },
    domProps: {
      "value": _vm.value
    },
    on: {
      "input": function (e) {
        return _vm.onInput(e.target.value);
      }
    }
  }) : _vm.type === 'textarea' ? _c('textarea', {
    staticClass: "form-control",
    class: {
      'is-invalid': !!_vm.errorMessage,
      'form-control-alt': _vm.isAlt || _vm.alt,
      disabled: _vm.disabled
    },
    attrs: {
      "id": _vm.id,
      "name": _vm.id,
      "disabled": _vm.disabled,
      "type": "text",
      "rows": "4",
      "placeholder": _vm.filledPlaceholder
    },
    domProps: {
      "value": _vm.value
    },
    on: {
      "input": function (e) {
        return _vm.onInput(e.target.value);
      }
    }
  }) : _vm.type === 'select-old' ? _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.itemValue,
      expression: "itemValue"
    }],
    staticClass: "form-control",
    class: {
      'is-invalid': !!_vm.errorMessage,
      'form-control-alt': _vm.isAlt || _vm.alt,
      disabled: _vm.disabled
    },
    attrs: {
      "id": _vm.id,
      "name": _vm.id,
      "disabled": _vm.disabled,
      "placeholder": _vm.filledPlaceholder
    },
    domProps: {
      "value": _vm.value
    },
    on: {
      "input": function (e) {
        return _vm.onInput(e.target.value);
      },
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.itemValue = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, [_vm.filledPlaceholder && !_vm.options.find(function (o) {
    return o.value === '' || o.value === null;
  }) ? _c('option', {
    attrs: {
      "value": "",
      "disabled": "",
      "selected": "",
      "hidden": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.filledPlaceholder) + " ")]) : _vm._e(), _vm._l(_vm.options, function (option) {
    return _c('option', {
      key: option.value,
      attrs: {
        "disabled": option.disabled
      },
      domProps: {
        "value": option.value
      }
    }, [_vm._v(_vm._s(option.label))]);
  })], 2) : _vm.type === 'select-multiple' ? _c('select', {
    staticClass: "form-control",
    class: {
      'is-invalid': !!_vm.errorMessage,
      'form-control-alt': _vm.isAlt || _vm.alt,
      disabled: _vm.disabled
    },
    attrs: {
      "id": _vm.id,
      "name": _vm.id,
      "disabled": _vm.disabled,
      "placeholder": _vm.filledPlaceholder,
      "multiple": "true"
    },
    on: {
      "input": function (e) {
        return _vm.onInput(Array(e.target.selectedOptions.length).fill().map(function (u, i) {
          return e.target.selectedOptions[i].value;
        }));
      }
    }
  }, _vm._l(_vm.options, function (option) {
    return _c('option', {
      key: option.value,
      domProps: {
        "value": option.value
      }
    }, [_vm._v(_vm._s(option.label))]);
  }), 0) : _vm.type === 'radio' ? _c('div', {
    class: {
      'is-invalid': !!_vm.error,
      'form-control-alt': _vm.isAlt || _vm.alt
    }
  }, _vm._l(_vm.options, function (option) {
    return _c('div', {
      key: `${_vm.id}-${option.value}`,
      staticClass: "custom-control custom-radio custom-control-inline custom-control-primary"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.itemValue,
        expression: "itemValue"
      }],
      staticClass: "custom-control-input",
      attrs: {
        "id": `${_vm.id}-${option.value}`,
        "type": "radio",
        "name": `${_vm.id}-${option.value}`,
        "disabled": _vm.disabled
      },
      domProps: {
        "value": `${option.value}`,
        "checked": _vm._q(_vm.itemValue, `${option.value}`)
      },
      on: {
        "input": function (e) {
          return _vm.onInput(e.target.value);
        },
        "change": function ($event) {
          _vm.itemValue = `${option.value}`;
        }
      }
    }), _c('label', {
      staticClass: "custom-control-label",
      attrs: {
        "for": `${_vm.id}-${option.value}`
      }
    }, [_vm._v(_vm._s(option.label))])]);
  }), 0) : _vm.type === 'file' ? _c('div', [_c('input', {
    attrs: {
      "id": _vm.id,
      "type": "file",
      "name": _vm.id,
      "multiple": _vm.multiple
    }
  })]) : _vm.type === 'slider' ? _c('RangeSlider', {
    attrs: {
      "id": _vm.id,
      "min": _vm.min,
      "max": _vm.max,
      "value": _vm.value,
      "type": _vm.sliderType,
      "postfix": "%"
    },
    on: {
      "onSlide": _vm.onInput
    }
  }) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }