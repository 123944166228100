<template>
  <div>
    <div class="MultiSelectOptions">
      <div v-if="search" class="form-group MultiSelectOptions-form mb-2">
        <div class="input-group">
          <input class="form-control" placeholder="Search..." @input="onSearch" />
          <div class="input-group-append">
            <span class="input-group-text">
              <i class="fa fa-fw fa-search"></i>
            </span>
          </div>
        </div>
      </div>

      <div v-if="!availableOptions || availableOptions.length === 0" class="alert alert-warning">No options available</div>

      <div v-else class="MultiSelectOptions-dropdown-wrapper">
        <ul class="MultiSelectOptions-dropdown">
          <li
            v-for="(item, index) in availableOptions"
            :key="index"
            class="MultiSelectOptions-dropdown-item d-flex justify-content-between align-items-center font-w600"
            @click.prevent="onClickCheckbox(item)"
          >
            <div class="d-flex align-items-center">
              <div class="DataTableColumns-checkbox custom-control custom-checkbox mr-3">
                <input
                  :id="`input-${item.value}`"
                  type="checkbox"
                  class="custom-control-input"
                  :name="`checkbox-${item.value}`"
                  :checked="item.checked"
                />
                <label class="custom-control-label" :for="`checkbox-${item.value}`"></label>
              </div>
              <div>{{ item.label }}</div>
            </div>
          </li>
        </ul>
        <div class="mt-3 d-flex align-items-end justify-content-end">
          <div class="text-muted font-w600 font-size-sm">{{ value.length }} items selected</div>
          <div class="ml-3">
            <a v-if="!availableOptions.every(a => a.checked)" href="#" class="text-primary font-w600 ml-2 mr-2" @click.prevent="onClickSelectAll"
              >Select All</a
            >
            <a v-if="availableOptions.every(a => a.checked)" href="#" class="text-primary font-w600 ml-2 mr-2" @click.prevent="onClickClear"
              >Unselect All</a
            >
            <a href="#" class="text-primary font-w600 ml-2" @click.prevent="onClickClear">Clear</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MultiSelectOptions',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    },
    overflow: {
      type: Boolean,
      default: false
    },
    search: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Options'
    },
    alt: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      results: {
        data: [],
        total: 0,
        limit: 10,
        skip: 0
      },
      page: 1,
      limit: 10,
      query: '',
      loading: false
    };
  },
  computed: {
    availableOptions() {
      return this.options
        .filter(option => !this.query || option.label.toLowerCase().includes(this.query.toLowerCase()))
        .map(option => ({
          ...option,
          checked: this.value.includes(option.value)
        }));
    }
  },
  methods: {
    onSearch(e) {
      this.query = e.target.value;
    },
    onClickCheckbox(item) {
      console.log('onClickCheckbox', item);
      if (item.checked) {
        this.removeFromList(item.value);
      } else {
        this.addToList(item);
      }
    },
    addToList(item) {
      const newValue = [...this.value, item.value];
      this.$emit('input', newValue);
      this.$emit('itemAdded', item.value);
    },
    removeFromList(item) {
      const newValue = [...this.value].filter(value => value !== item);

      this.$emit('input', newValue);
      this.$emit('itemRemoved', item);
    },
    onClickSelectAll() {
      const newValue = [...this.value, ...this.options.map(o => o.value)];
      this.$emit('input', newValue);
    },
    onClickClear() {
      this.$emit('input', []);
    }
  }
};
</script>

<style lang="scss">
@import '~@/assets/_scss/custom/variables';

.MultiSelectOptions {
  position: relative;
}

.MultiSelectOptions-form {
  position: relative;
}

.MultiSelectOptions-dropdown-wrapper {
  border: 2px solid $gray-lines;
  border-radius: 0.3rem;
  width: 100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0.45rem;
}

.MultiSelectOptions-dropdown {
  padding: 0 0.375rem 0 0;
  margin: 0;
  overflow-y: scroll;
  max-height: 275px;
}

.form-control-alt .MultiSelectOptions-dropdown-wrapper {
  background: #fff;
}

.form-control-alt .MultiSelectOptions .input-group-text {
  background: $primary;
  color: #fff;
}

// .MultiSelectOptions-dropdown::-webkit-scrollbar {
//   -webkit-appearance: none;
//   width: 7px;
// }

// .MultiSelectOptions-dropdown::-webkit-scrollbar-thumb {
//   border-radius: 4px;
//   background-color: $gray-lines;
//   -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
// }

.MultiSelectOptions-dropdown-item {
  margin: 0;
  padding: 5px 8px;
  cursor: pointer;
}

.MultiSelectOptions-dropdown-item:hover {
  background: $gray-hover;
  border-radius: 5px;
}

.MultiSelectOptions {
  &-item {
    position: relative;
    margin-left: 20px;
  }
  &-item:after {
    background: $primary;
    border-radius: 6px;
    content: '';
    height: 70%;
    left: -14px;
    position: absolute;
    width: 4px;
    top: calc(15%);
    padding: 5px 0;
  }
}
</style>
